function populateDropdownOptions(id, options) {
  $("#" + id).empty().html("<option value=''>All</option>");
  if (options !== null && options.length > 0) {
    $.each(options, function (index, option) {
      var optionValue = option.id;
      var optionText = option.currency && option.currency.name ? option.currency.name : option.entity_registration && option.entity_registration.name ? option.entity_registration.name : option.name;
      $("#" + id).append('<option value="' + optionValue + '">' + optionText + "</option>");
    });
  }
}

function fetchDataAndPopulateDropdowns(url, data, dropdownId, optionsKey) {
  $.ajax({
    type: "GET",
    url: url,
    dataType: "json",
    data: data,
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      handleResponse(response)
      var options = response[optionsKey];
      populateDropdownOptions(dropdownId, options);
    },
  });
}


function updateTotal() {
  var total = 0;
  $(".prepare-checkbox:checked").each(function () {
    total += parseFloat($(this).closest("tr").find(".transfer-amount").text());
  });
  $(".total").text(total.toFixed(2));
}

$(document).on("change", "#transfer-to, #bene-currency, #correspondent-select, #delivery-type", function () {
  var destination_country = $("#transfer-to").val();
  var beneCurrency = $("#bene-currency").val();
  var correspondent = $("#correspondent-select").val();
  var deliveryType = $("#delivery-type").val();
  var status = $("#status").val()
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  var url = window.location.pathname;

  if ($(this).val() === "") {
    if ($(this).is("#transfer-to")) {
      destination_country = null;
    } else if ($(this).is("#bene-currency")) {
      beneCurrency = null;
    } else if ($(this).is("#correspondent-select")) {
      correspondent = null;
    } else if ($(this).is("#delivery-type")) {
      deliveryType = null;
    }
  }

  if ($(this).is("#transfer-to")) {
    $("#correspondent-select, #product").empty().html("<option value=''>All</option>");
    $("#delivery-type").val("")
    fetchDataAndPopulateDropdowns(url, { transfer_to: destination_country, status: status, from_date: fromDate, to_date: toDate }, "bene-currency", "bene_currencies");
  } else if ($(this).is("#bene-currency")) {
    $("#correspondent-select, #product").empty().html("<option value=''>All</option>");
    $("#delivery-type").val("")
    fetchDataAndPopulateDropdowns(url, { transfer_to: destination_country, bene_currency: beneCurrency, status: status, from_date: fromDate, to_date: toDate }, "correspondent-select", "correspondents");
  } else if ($(this).is("#correspondent-select")) {
    $("#delivery-type").val("")
    fetchDataAndPopulateDropdowns(url, { transfer_to: destination_country, bene_currency: beneCurrency, correspondent: correspondent, status: status, from_date: fromDate, to_date: toDate }, "product", "products");
  } else if ($(this).is("#delivery-type")) {
    fetchDataAndPopulateDropdowns(url, { transfer_to: destination_country, bene_currency: beneCurrency, correspondent: correspondent, delivery_type: deliveryType, status: status, from_date: fromDate, to_date: toDate }, "product", "products");
  }

});

$(document).on("change", "#product", function () {
  var destination_country = $("#transfer-to").val();
  var beneCurrency = $("#bene-currency").val();
  var correspondent = $("#correspondent-select").val();
  var deliverType = $("#delivery-type").val()
  var product = $("#product").val();
  var status = $("#product").val();
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  data = {

    transfer_to: destination_country,
    bene_currency: beneCurrency,
    correspondent: correspondent,
    delivery_type: deliverType,
    product: product,
    status: status,
    from_date: fromDate,
    to_date: toDate
  }
  handleAJaxResponse(data)

});

$(document).on("change", "#status", function () {
  var destination_country = $("#transfer-to").val();
  var beneCurrency = $("#bene-currency").val();
  var correspondent = $("#correspondent-select").val();
  var deliverType = $("#delivery-type").val()
  var product = $("#product").val();
  var status = $(this).val()
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  data = {
    transfer_to: destination_country,
    bene_currency: beneCurrency,
    correspondent: correspondent,
    delivery_type: deliverType,
    product: product,
    status: status,
    from_date: fromDate,
    to_date: toDate
  }
  handleAJaxResponse(data)

});


$(".from-date").change(function () {
  var destination_country = $("#transfer-to").val();
  var beneCurrency = $("#bene-currency").val();
  var correspondent = $("#correspondent-select").val();
  var deliverType = $("#delivery-type").val()
  var product = $("#product").val();
  var status = $("#status").val()
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  data = {
    transfer_to: destination_country,
    bene_currency: beneCurrency,
    correspondent: correspondent,
    delivery_type: deliverType,
    product: product,
    status: status,
    from_date: fromDate,
    to_date: toDate
  }
  handleAJaxResponse(data)
})

$(".to-date").change(function () {
  var destination_country = $("#transfer-to").val();
  var beneCurrency = $("#bene-currency").val();
  var correspondent = $("#correspondent-select").val();
  var deliverType = $("#delivery-type").val()
  var product = $("#product").val();
  var status = $("#status").val()
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  data = {
    transfer_to: destination_country,
    bene_currency: beneCurrency,
    correspondent: correspondent,
    delivery_type: deliverType,
    product: product,
    status: status,
    from_date: fromDate,
    to_date: toDate
  }
  handleAJaxResponse(data)
})

function handleAJaxResponse(data) {
  $.ajax({
    type: "GET",
    url: window.location.pathname,
    dataType: "json",
    data: data,
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      handleResponse(response)
    },
  });
}


$(document).on("change", ".prepare-checkbox", function () {
  $(".prepare-btn").prop(
    "disabled",
    $(".prepare-checkbox").filter(":checked").length < 1
  );
  updateTotal();
}).change();

$(document).on("change", ".approval-checkbox", function () {

  var shouldShowApprovalBtn = true;
  var anyChecked = $('.approval-checkbox').is(':checked');
  $(".approval-checkbox:checked").each(function () {
    var $row = $(this).closest("tr");
    // $(".approval-btn").hide();
    if ($row.find(".status-check").text().trim() === "Opened" && $(".approval-checkbox").filter(":checked").length == 1) {
      // shouldShowApprovalBtn = true;
      // $(".approval-btn").show();
      return false;
    }
  });
  $(".approval-btn").prop("disabled", !anyChecked);
  if ($(".approval-checkbox").filter(":checked").length == 1) {
    $('.remove-transactions-modal-trigger').show()
  } else {
    $('.remove-transactions-modal-trigger').hide()
  }
}).change();

function handleResponse(response) {
  if (window.location.pathname === "/purchase_orders/prepare") {
    var product = $("#product").val();
    var correspondent = $("#correspondent-select").val();
    var transactions = response.transactions;
    $(".get-po-transactions").empty();
    $(".total").text("0.00");
    if (transactions.length > 0) {
      var currencyCode = transactions[0].bene_currency.currency.code;
      $(".bene-currency-code").empty().text(currencyCode);
      var rows = "";
      for (var i = 0; i < transactions.length; i++) {
        rows +=
          "<tr>" +
          `<td>` +
          `<input type="checkbox" name="purchase_order[transfer_transaction_ids][]" id="purchase_order_transfer_transaction_ids_" value=${transactions[i].id} class="prepare-checkbox">` +
          "</td>" +
          `<td class="text-center">${transactions[i].product.name}</td>` +
          `<td class="text-center">${transactions[i].transfer_to.name}</td>` +
          `<td class="text-center">${transactions[i].bene_currency.currency.name}</td>` +
          `<td class="text-center">${transactions[i].created_at}</td>` +
          `<td class="text-center transfer-amount">${transactions[i].transfer_amount}</td>` +
          `<td class="text-center">${transactions[i].customer.first_name} ${transactions[i].customer.last_name}</td>` +
          `<td class="text-center">${transactions[i].bene.first_name} ${transactions[i].bene.last_name}</td>` +
          "</tr>";
      }
      $(rows).hide().appendTo(".get-po-transactions").fadeIn();
      if (correspondent && correspondent.trim() !== '') {
        $(".prepare-checkbox, #tt-select-all").prop("disabled", false);
      } else {
        $(".prepare-checkbox, #tt-select-all").prop("disabled", true);
      }
    } else {
      $(".get-po-transactions").empty();
    }
  } else if (window.location.pathname === "/purchase_orders/approval") {
    $(".approval-table-body").empty();
    var purchaseOrders = response.purchase_orders;
    console.log(purchaseOrders)
    if (purchaseOrders.length > 0) {
      for (var i = 0; i < purchaseOrders.length; i++) {
        var row = $("<tr>")
          .append(
            $("<td>").append(
              `<input type="checkbox" name="purchase_order_ids[]" id="purchase_order_ids_" value=${purchaseOrders[i].id} class="approval-checkbox">`
            )
          )
          .append($("<td>").addClass("text-center").text(purchaseOrders[i].transfer_transactions[0].product.name))
          .append(
            $("<td>")
              .addClass("text-center")
              .text(purchaseOrders[i].transfer_transactions[0].bene_currency.currency.name)
          )
          .append(
            $("<td>")
              .addClass("text-center")
              .text(purchaseOrders[i].created_at)
          )
          .append(
            $("<td>")
              .addClass("text-center")
              .text(purchaseOrders[i].total_fc_amount)
          ).append(
            $("<td>")
              .addClass("text-center")
              .text(purchaseOrders[i].id)
          ).append(
            $("<td>")
              .addClass("text-center")
              .text(purchaseOrders[i].transfer_transactions.length)
          ).append(
            $("<td>")
              .addClass("text-center status-check")
              .text(purchaseOrders[i].approved == true ? "Approved" : "Opened")
          )
          .hide();

        $(".approval-table-body").append(row);
      }

      $(".approval-table-body tr").fadeIn();
    } else {
      $(".approval-table-body").empty();
    }
  } else {
    var purchaseOrders = response.purchase_orders;
    console.log(purchaseOrders)
    if (purchaseOrders.length > 0) {
      $(".get-file-po").empty();
      for (var i = 0; i < purchaseOrders.length; i++) {
        if (purchaseOrders[i].product_name_includes_seylan) {
          var seylanHeaderFileName = purchaseOrders[i].file_name.split('.').map((part, index, parts) => {
            return index === parts.length - 1 ? 'HDR' : part;
          }).join('.');
          $(".get-file-po").append(
            `<tr>` +
            `<td class="text-center">` +
            `<a class="btn btn-icon btn_primary" download="true" href="/purchase_orders/${purchaseOrders[i].id}/seylan_file_download.${purchaseOrders[i].file_request_format}"><span class="las la-file-download"></span></a>` +
            `</td>` +
            `<td class="text-center">${seylanHeaderFileName}</td>` +
            `<td class="text-center">${purchaseOrders[i].transfer_transactions
              .map(t => t.product.correspondent.name)
              .filter((value, index, self) => self.indexOf(value) === index)
              .join(", ")}</td>` +
            `<td class="text-center">${purchaseOrders[i].created_at}</td>` +
            `<td class="text-center">${purchaseOrders[i].id}</td>` +
            `<td class="text-center">${purchaseOrders[i].transfer_transactions.length}</td>` +
            `<td class="text-center">${purchaseOrders[i].total_amount}</td>` +
            `</tr>`
          );
        }
        $(".get-file-po").append(
          `<tr>` +
          `<td class="text-center">` +
          `<a class="btn btn-icon btn_primary" download="true" href="/purchase_orders/${purchaseOrders[i].id}/file_download.${purchaseOrders[i].file_request_format}"><span class="las la-file-download"></span>` +
          `</a>` +
          `</td>` +
          // `<td class="text-center">PurchaseOrder-${purchaseOrders[i].id}-TransactionDetails.xlsx</td>` +
          `<td class= "text-center">${purchaseOrders[i].file_name} </td>` +
          `<td class="text-center">${purchaseOrders[i].transfer_transactions
            .map((t) => t.product.correspondent.name)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ")}</td>` +
          `<td class="text-center">${purchaseOrders[i].created_at}</td>` +
          `<td class="text-center">${purchaseOrders[i].id}</td>` +
          `<td class="text-center">${purchaseOrders[i].transfer_transactions.length}</td>` +
          `<td class="text-center">${purchaseOrders[i].total_fc_amount}</td>` +
          `</tr>`
        );
      }
    } else {
      $(".get-file-po").empty();
    }
  }
}

$(".remove-transactions-modal-trigger").click(function () {
  let checkedCheckbox = $(".approval-checkbox:checked");
  if (checkedCheckbox.length == 1) {
    let checkPoId = checkedCheckbox.val();
    $.ajax({
      url: `/purchase_orders/${checkPoId}/list_transactions`,
      method: "GET",
      dataType: "html",
      success: function (response) {
        $("#removeTransactionsModalContent").html(response)
        $("#removeTransactionsModal").show();
      },
      error: function (xhr, status, error) {
        console.error(error);
      },
    });
  }
})

$(".close-modal").click(function () {
  $("#removeTransactionsModalContent").empty();
  $("#removeTransactionsModal").hide();
});